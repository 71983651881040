@import url(CSS:../../bower_components/bootstrap/dist/css/bootstrap.css);
@import url(CSS:../../bower_components/bx-slider.js/dist/jquery.bxslider.css);
/*
* Third Party
*/
/*
* Custom
*/
html, body {
  height: 100%;
  font-family: 'Open Sans', sans-serif; }

.row {
  margin-left: 0;
  margin-right: 0; }

.head-bg {
  height: auto;
  background-image: url('../img/head.jpg');
  background-size: cover;
  background-position: center center; }

.banner-content {
  background: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  display: block;
  height: auto;
  color: #FFF; }

.pdt15 {
  padding-top: 15px; }

.sh_icon {
  padding-bottom: 10px; }

h1.h1-head {
  color: #26517E;
  font-size: 2em;
  margin: 0;
  padding-bottom: 15px; }

p.p-head {
  color: #26517E;
  font-size: 1.5em;
  margin: 0; }

h2.h2-head {
  color: #26517E;
  font-size: 1.5em;
  margin: 0; }

.padd0, .head-bg .col-md-5, .head-bg .col-md-3 {
  padding: 0; }

@media (min-width: 768px) {
  .navbar .navbar-nav {
    display: inline-block;
    float: none;
    vertical-align: top; }
  .navbar .navbar-collapse {
    text-align: center; } }

.navbar {
  background-color: #26517E;
  text-transform: uppercase;
  border-radius: 0;
  border: none;
  margin: 0;
  width: 100%;
  z-index: 9999;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.16), 0px 2px 5px 0px rgba(0, 0, 0, 0.23); }

.navbar-default .navbar-nav > li > a {
  color: #FFF;
  padding: 20px;
  font-weight: bold;
  border-color: none; }

.navbar-toggle {
  margin-top: 12px;
  margin-right: 15px;
  margin-bottom: 12px; }

.welcome_image {
  height: 580px;
  background-image: url('../img/volvobl71b.jpg');
  background-size: cover;
  background-position: center center;
  position: absolute;
  width: 100%; }

.welcome_image2 {
  height: 580px;
  background-image: url('../img/26102011411.jpg');
  background-size: cover;
  background-position: center center;
  position: absolute;
  width: 100%; }

#service, #callback-form {
  padding: 0; }

.servicez_temp {
  margin: 25px; }

#hidden {
  visibility: hidden;
  margin-top: 80px;
  padding: 0; }

#mailme {
  margin-top: 30px; }

.bx-wrapper {
  position: absolute;
  width: 100%;
  border: 0;
  padding: 0; }

.bxslider img {
  height: 580px;
  width: auto;
  background-size: cover;
  background-position: center center; }

.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
  background-color: none; }

.navbar-default .navbar-toggle .icon-bar {
  background-color: #FFF; }

.navbar-default .navbar-brand {
  padding-top: 20px;
  padding-bottom: 20px;
  height: 60px;
  font-weight: 900;
  color: #FFF;
  display: none; }

.navbar-default .navbar-brand:focus {
  color: #FFF; }

section {
  padding: 50px 0;
  height: auto;
  width: 100%;
  display: block;
  float: none; }

.navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a:hover {
  color: #139FDD; }

input, select {
  background-color: #3C6897;
  padding: 10px 29px;
  border-radius: 5px;
  color: #FFF;
  border: 0; }

select {
  padding: 10px 67px 10px 66px; }

option:active {
  background-color: #FFF; }

input[placeholder] {
  color: #FFF; }

textarea {
  resize: none; }

footer {
  width: 100%;
  display: block;
  height: auto;
  background-color: #191919;
  text-align: center;
  font-weight: bold;
  vertical-align: middle;
  color: #FFF;
  padding: 20px 25px; }

#about_me {
  background-color: #FFF; }

#contact_form {
  background-image: url('../img/bottom-form-bg.jpg');
  background-size: cover;
  background-position: center center;
  margin-top: -60px; }

#reviews {
  margin-bottom: 50px;
  color: #0B1D35; }
  #reviews h1 {
    color: #0B1D35; }

#callmenow {
  color: #0B1D35; }

h1.h1-custom {
  color: #0B1D35;
  font-size: 45px;
  text-transform: uppercase;
  font-weight: 900; }

.vertical-center-table {
  float: none;
  width: 100%;
  height: 100%;
  display: table;
  /*margin-right: -4px;*/ }

.vertical-center-cell {
  display: table-cell;
  vertical-align: middle;
  height: 550px; }
  .vertical-center-cell h2 {
    word-break: break-word; }
  .vertical-center-cell h1 {
    word-break: break-word; }
  .vertical-center-cell h4 {
    word-break: break-word; }

.popup {
  display: none;
  position: absolute;
  width: 350px;
  margin: 0 auto;
  text-align: center;
  top: 45%;
  left: 40%; }

#banner_first, #banner_second {
  visibility: hidden; }

form {
  visibility: hidden;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.16), 0px 2px 5px 0px rgba(0, 0, 0, 0.23);
  width: 300px;
  background: #26517E;
  border-radius: 8px; }
  form p {
    font-weight: bold;
    color: #FFF;
    width: 260px;
    display: block;
    margin: 0 auto;
    padding: 20px 0; }
  form button {
    background-color: #139FDD;
    border-radius: 5px;
    width: 261px;
    height: 40px;
    margin: 20px;
    font-weight: bold;
    color: #FFF;
    border: 0;
    vertical-align: middle;
    text-align: center; }

.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
  padding: 15px; }

#contact_form {
  height: 100%;
  padding: 0; }

input::-moz-placeholder {
  color: #FFF;
  opacity: 1; }

input:-ms-input-placeholder {
  color: #FFF; }

input::-webkit-input-placeholder {
  color: #FFF; }

.d-table {
  height: 100%;
  display: table; }

.d-table-cell {
  display: table-cell;
  vertical-align: middle;
  height: 129px; }

#sixreasons {
  background-color: rgba(0, 0, 0, 0.5);
  color: #FFF; }
  #sixreasons h1 {
    color: #FFF; }

.mutual-bg {
  background-image: url('../img/blue-polygon.jpg');
  background-size: cover;
  background-position: center center;
  background-attachment: fixed; }

#work-shema {
  color: #FFF; }
  #work-shema h1 {
    color: #FFF; }

.pt50 {
  padding-top: 50px; }

@media screen and (max-width: 767px) {
  .navbar-default .navbar-brand {
    display: block; } }

.height100 {
  height: 100%;
  min-height: 550px; }

.height100 .col-md-4 {
  top: 17%; }

@media screen and (max-width: 1198px) {
  .rotate {
    transform: rotate(90deg); } }

a.footer_link {
  color: #139FDD; }

a.footer_link:hover {
  color: #FFF; }

a.footer_link:visited {
  color: #3C6897; }

@media screen and (max-width: 991px) {
  .mobile-text-center {
    text-align: center; } }

@media screen and (max-width: 1021px) {
  .banner-content {
    background: none;
    color: #0B1D35;
    text-align: center; } }

.phone-wrapper {
  width: 225px;
  margin: 0 auto; }

.mg0 {
  margin: 0; }

.rounded {
  border-radius: 100%; }

